import React, { useEffect, useState, useContext } from "react"
import { document } from "browser-monads"
import Flickity from "flickity"
import vid1 from "../videos/test-3.mp4"
import vid2 from "../videos/merged-small-ls_A.mp4"
import vid3 from "../videos/big-1.mp4"
import vid4 from "../videos/big-2.mp4"
import image1 from "../images/12.jpg"
import image2 from "../images/11.jpg"
import image3 from "../images/14.jpg"
import image4 from "../images/2.jpg"
import image5 from "../images/MagicShowPoster.jpg"
import { UserStateContext } from "../components/layout"
import { Link } from "gatsby"
import "./index.css"
import "./styles.scss"
import "animate.css"

export default function Home(props) {
  useEffect(() => {
    var elem = document.querySelector(
      ".store-section .image-slider .image-slider-wrap .carousel"
    )
    var flkty = new Flickity(elem, {
      contain: true,
      freeScroll: true,
      prevNextButtons: false,
      pageDots: false,
    })
  }, [])

  const scroll = useContext(UserStateContext)
  const [firstSet, setFirstSet] = useState(false)
  const [secondSet, setSecondSet] = useState(false)
  const [footerSet, setFooterSet] = useState(false)
  useEffect(() => {
    if (scroll.scrollTop > 150 && scroll.clientWidth > 1800) {
      setFirstSet(true)
    }
    if (scroll.scrollTop > 1050 && scroll.clientWidth > 1800) {
      setSecondSet(true)
    }
    if (scroll.scrollTop > 2350 && scroll.clientWidth > 1800) {
      setFooterSet(true)
    }
  }, [scroll.scrollTop])
  //CSS ELEMENT
  useEffect(() => {
    let elements = document.querySelectorAll(".index-rolling-text")

    elements.forEach(element => {
      let innerText = element.innerText
      element.innerHTML = ""

      let textContainer = document.createElement("div")
      textContainer.classList.add("block")

      for (let letter of innerText) {
        let span = document.createElement("span")
        span.innerText = letter.trim() === "" ? "\xa0" : letter
        span.classList.add("letter")
        textContainer.appendChild(span)
      }

      element.appendChild(textContainer)
      element.appendChild(textContainer.cloneNode(true))
    })
  }, [])
  return (
    <div className="home">
      <div>
        <section className="intro">
          <div style={{ position: "relative" }}>
            <div className="main">
              <div className="content">
                <div className="video-mask">
                  <div className="video">
                    <div className="video-wrapper">
                      <video
                        autoplay="autoplay"
                        preload
                        playsinline
                        muted="muted"
                        loop="loop"
                        poster="./images/homepage-video02-2.jpg"
                        className="animate__animated animate__slideInRight animate__delay-1s"
                      >
                        <source src={vid1} type="video/mp4" />
                        <track
                          src={vid1}
                          kind="captions"
                          srclang="en"
                          label="english_captions"
                        />
                      </video>
                    </div>
                  </div>
                </div>
                <div className="video-mask-2">
                  <div className="video">
                    <div className="video-wrapper">
                      <video
                        autoplay="autoplay"
                        preload
                        playsinline
                        muted="muted"
                        loop="loop"
                        poster="./images/homepage-video02-2.jpg"
                        className="animate__animated animate__slideInLeft animate__delay-1s"
                      >
                        <source src={vid2} type="video/mp4" />
                        <track
                          src={vid2}
                          kind="captions"
                          srclang="en"
                          label="english_captions"
                        />
                      </video>
                    </div>
                  </div>
                </div>
                <div className="text-mask">
                  <div className="parent">
                    <div className="text animate__animated animate__slideInUp">
                      Dedicated to aiding
                    </div>
                  </div>
                  <div className="parent">
                    <div className="text animate__animated animate__slideInUp delay-1">
                      struggling communities
                    </div>
                  </div>
                  <div className="parent">
                    <div className="text animate__animated animate__slideInUp delay-2">
                      in Mount Lebanon.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="text-image-1">
          <div className="main">
            <div className="text-content">
              <div
                className={
                  firstSet
                    ? "text-top animate__animated animate__fadeInRight"
                    : "text-top"
                }
              >
                How we help struggling families, small businesses, and local
                establishments
              </div>
              <div className="text-bot">
                <div style={{ display: "contents" }}>
                  <p
                    className={
                      firstSet
                        ? "animate__animated animate__fadeInRight delay-1"
                        : ""
                    }
                  >
                    <p>
                      <b>
                        The United Nations now estimates that 82% of people in
                        Lebanon live below the poverty line, and 36% of the
                        population is living in extreme poverty.
                      </b>
                    </p>
                    The Beytna Foundation, a registered 501(c)(3) organization,
                    hosts private fundraiser events throughout the year. We also
                    raise money through other initiatives such as book sales and
                    our merchandise store. As a 501(c)(3) organization, all
                    funds we raise are sent to Lebanon to provide miscellaneous
                    services to people in need. We work closely with a small,
                    local community that we know well, which allows us to assess
                    their needs directly so we can best fulfill them. Our
                    non-profit status ensures that all our operations are
                    transparent and dedicated to our mission of helping those in
                    need.
                  </p>
                  <div className="text-bot-button-1">
                    <Link
                      to="/what-we-do/#home"
                      target="_blank"
                      rel="noreferrer"
                      className="content-head"
                    >
                      <div className="content-1">
                        <div className="label">
                          <div className="index-rolling-text">what we do</div>
                        </div>
                        <div class="wrapper">
                          <Link
                            href="/what-we-do/#home"
                            target="_blank"
                            rel="noreferrer"
                            class="chevron-d"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="55"
                              height="55"
                              viewBox="0 0 55 55"
                            >
                              <path
                                class="circle"
                                d="M27.5 2C41.6 2 53 13.4 53 27.5S41.6 53 27.5 53 2 41.6 2 27.5 13.4 2 27.5 2M27.5 0C12.3 0 0 12.3 0 27.5S12.3 55 27.5 55 55 42.7 55 27.5 42.7 0 27.5 0L27.5 0z"
                              />
                              <polygon
                                class="arrow"
                                points="34.9 31 28.5 35 28.5 17 26.5 17 26.5 35 20.1 31 19 32.7 27.5 38 36 32.7 "
                              />
                              <polygon
                                class="arrow-2"
                                points="34.9 31 28.5 35 28.5 17 26.5 17 26.5 35 20.1 31 19 32.7 27.5 38 36 32.7 "
                              />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-content">
              <div className="video-wrapper">
                <video
                  className={
                    firstSet
                      ? "video animate__animated animate__fadeInLeft"
                      : "video"
                  }
                  autoplay="autoplay"
                  preload
                  playsinline
                  muted="muted"
                  loop="loop"
                  poster="./images/homepage-video02-2.jpg"
                >
                  <source src={vid3} type="video/mp4" />
                  <track
                    src={vid3}
                    kind="captions"
                    srclang="en"
                    label="english_captions"
                  />
                </video>
                <div className="mask-1" />
                <div className="mask-2" />
                <div className="overlay-1" />
                <div className="overlay-2" />
                <div className="overlay-3" />
              </div>
            </div>
          </div>
        </section>
        <section className="text-image-2">
          <div className="main">
            <div className="video-content">
              <div className="video-wrapper">
                <video
                  className={
                    secondSet
                      ? "video animate__animated animate__fadeInLeft"
                      : "video"
                  }
                  autoplay="autoplay"
                  preload
                  playsinline
                  muted="muted"
                  loop="loop"
                  poster="./images/homepage-video02-2.jpg"
                >
                  <source src={vid4} type="video/mp4" />
                  <track
                    src={vid4}
                    kind="captions"
                    srclang="en"
                    label="english_captions"
                  />
                </video>
                <div className="mask-2" />
                <div className="mask-1" />
                <div className="overlay-1" />
                <div className="overlay-2" />
                <div className="overlay-3" />
              </div>
            </div>
            <div className="text-content">
              <div
                className={
                  secondSet
                    ? "text-top animate__animated animate__fadeInRight delay-1"
                    : "text-top"
                }
              >
                Our devotion to Lebanon
              </div>
              <div className="text-bot">
                <div style={{ display: "contents" }}>
                  <p
                    className={
                      secondSet
                        ? "animate__animated animate__fadeInRight delay-2"
                        : ""
                    }
                  >
                    <i>Beytna</i> is Arabic for “our home.” Throughout our
                    lives, no matter the circumstances, our homes remain open.
                    The biggest honor for a Lebanese person is to host, help and
                    feed visitors. Through this foundation, we will continue
                    this tradition by extending the reach of our hospitality and
                    desire to help by strengthening our communities. Though we
                    are oceans away, Lebanon is still our home.
                  </p>
                  <div className="text-bot-button-2">
                    <Link
                      to="/about/#home"
                      target="_blank"
                      rel="noreferrer"
                      className="content-head"
                    >
                      <div className="content-1">
                        <div className="label">
                          <div className="index-rolling-text">about us</div>
                        </div>
                        <div class="wrapper">
                          <Link
                            href="/about/#home"
                            target="_blank"
                            rel="noreferrer"
                            class="chevron-d"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="55"
                              height="55"
                              viewBox="0 0 55 55"
                            >
                              <path
                                class="circle"
                                d="M27.5 2C41.6 2 53 13.4 53 27.5S41.6 53 27.5 53 2 41.6 2 27.5 13.4 2 27.5 2M27.5 0C12.3 0 0 12.3 0 27.5S12.3 55 27.5 55 55 42.7 55 27.5 42.7 0 27.5 0L27.5 0z"
                              />
                              <polygon
                                class="arrow"
                                points="34.9 31 28.5 35 28.5 17 26.5 17 26.5 35 20.1 31 19 32.7 27.5 38 36 32.7 "
                              />
                              <polygon
                                class="arrow-2"
                                points="34.9 31 28.5 35 28.5 17 26.5 17 26.5 35 20.1 31 19 32.7 27.5 38 36 32.7 "
                              />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="store-section">
          <div className="body-text-sub">These are our products</div>
          <div className="body-text-container">
            <div className="body-text-headline">Explore our shop</div>
            <a
              className="shop-mobile-button"
              href="https://www.beytna-store.com/"
              target="_blank"
              rel="noreferrer"
            >
              All Shop Apparel
            </a>
          </div>
          <div className="image-slider">
            <div className="image-slider-wrap">
              <div className="carousel">
                <div className="item">
                  <a
                    href="https://www.beytna-store.com/listing/get-men-classic-hoodie?product=212"
                    className="item-card"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="item-card-content">
                      <div className="item-card-type">Men's Apparel</div>
                      <div className="item-card-image">
                        <div className="item-card-image-wrap">
                          <div className="item-card-image-parallax">
                            <div className="item-card-image-img">
                              <picture>
                                <source />
                                <img
                                  className="image"
                                  src={image4}
                                  alt="men's hoodie"
                                />
                              </picture>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item-card-text">
                        <div className="item-card-subtitle">$42.99</div>
                        <div className="item-card-headline">
                          Men's Classic Hoodie
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="item">
                  <a
                    href="https://www.beytna-store.com/listing/beytna-w-hoodie?product=1305"
                    className="item-card"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="item-card-content">
                      <div className="item-card-type">Women's Apparel</div>
                      <div className="item-card-image">
                        <div className="item-card-image-wrap">
                          <div className="item-card-image-parallax">
                            <div className="item-card-image-img">
                              <picture>
                                <source />
                                <img
                                  className="image"
                                  src={image1}
                                  alt="women's hoodie"
                                />
                              </picture>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item-card-text">
                        <div className="item-card-subtitle">$42.99</div>
                        <div className="item-card-headline">Women's Hoodie</div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="item">
                  <a
                    href="https://www.beytna-store.com/listing/classic-logo-unisex-crewneck?product=345"
                    className="item-card"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="item-card-content">
                      <div className="item-card-type">Unisex Apparel</div>
                      <div className="item-card-image">
                        <div className="item-card-image-wrap">
                          <div className="item-card-image-parallax">
                            <div className="item-card-image-img">
                              <picture>
                                <source />
                                <img
                                  className="image"
                                  src={image3}
                                  alt="crewneck sweatshirt"
                                />
                              </picture>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item-card-text">
                        <div className="item-card-subtitle">$34.99</div>
                        <div className="item-card-headline">
                          Unisex Crewneck Sweatshirt
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="item">
                  <a
                    href="https://www.beytna-store.com/listing/logo-unisex-zip-up?product=1027"
                    className="item-card"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="item-card-content">
                      <div className="item-card-type">Unisex Apparel</div>
                      <div className="item-card-image">
                        <div className="item-card-image-wrap">
                          <div className="item-card-image-parallax">
                            <div className="item-card-image-img">
                              <picture>
                                <source />
                                <img
                                  className="image"
                                  src={image2}
                                  alt="zip hoodie"
                                />
                              </picture>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item-card-text">
                        <div className="item-card-subtitle">$40.99</div>
                        <div className="item-card-headline">
                          Unisex Zip Hoodie
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="item-button">
            <a
              href="https://www.beytna-store.com/"
              target="_blank"
              rel="noreferrer"
              className="content-head"
            >
              <div className="content-1">
                <div className="label">
                  <div className="index-rolling-text">Shop</div>
                </div>
                <div class="wrapper">
                  <a
                    href="https://www.beytna-store.com/"
                    target="_blank"
                    rel="noreferrer"
                    class="chevron-d"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="55"
                      height="55"
                      viewBox="0 0 55 55"
                    >
                      <path
                        class="circle"
                        d="M27.5 2C41.6 2 53 13.4 53 27.5S41.6 53 27.5 53 2 41.6 2 27.5 13.4 2 27.5 2M27.5 0C12.3 0 0 12.3 0 27.5S12.3 55 27.5 55 55 42.7 55 27.5 42.7 0 27.5 0L27.5 0z"
                      />
                      <polygon
                        class="arrow"
                        points="34.9 31 28.5 35 28.5 17 26.5 17 26.5 35 20.1 31 19 32.7 27.5 38 36 32.7 "
                      />
                      <polygon
                        class="arrow-2"
                        points="34.9 31 28.5 35 28.5 17 26.5 17 26.5 35 20.1 31 19 32.7 27.5 38 36 32.7 "
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </section> */}
        <section id="events" className="events-section">
          <section className="events">
            <div className="events-wrap">
              <div className="events-title">
                <div className="events-headline">
                  Events
                  <div className="line"></div>
                </div>
              </div>
              <div className="events-list">
                <div className="list">
                  <div className="events-item">
                    <div className="events-item-body"></div>
                    <div className="events-item-headline">
                      More Info To Come
                    </div>
                    <div
                      className="events-item-body-2"
                      style={{ color: "white", marginTop: "0.7518796992vw" }}
                    >
                      {/* The winter season can be a harsh one for the small towns
                      in Mount-Lebanon. People are getting{" "}
                      <span style={{ color: "#be202e" }}>two hours</span> of
                      electricity a day. In the winter, the matn region sits at
                      about 1000 meter above sea level. There is snow for most
                      of the winter. The local currency{" "}
                      <span style={{ color: "#be202e" }}>collapsed</span>{" "}
                      sliding from 1,500 to the dollar before the latest{" "}
                      <span style={{ color: "#be202e" }}>crisis</span> , to a
                      street rate of about 34,000 earlier this month. People are{" "}
                      <span style={{ color: "#be202e" }}>struggling</span> to
                      earn a living, provide basic necessities for their{" "}
                      <span style={{ color: "#be202e" }}>children</span> and in
                      the winter this dilemma will increase 10 fold because the
                      temperatures{" "}
                      <span style={{ color: "#be202e" }}>drop</span> to an
                      average of{" "}
                      <span style={{ color: "#be202e" }}>
                        13 degrees C (55 F)
                      </span>{" "}
                      during the day and{" "}
                      <span style={{ color: "#be202e" }}>
                        2 degrees C (35 F)
                      </span>{" "}
                      during the night. If you are in the CSRA and would like to{" "}
                      <span style={{ color: "#be202e" }}>donate</span> some
                      gently used winter coats and winter wear, please contact
                      us at{" "}
                      <span style={{ color: "#be202e" }}>
                        info@beytna.foundation
                      </span>{" "}
                      to arrange a pick-up/drop-off time.{" "} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <footer className="footer">
          <div className="main">
            <div className="interest">
              <div className="headline">
                <div className="parent">
                  <div
                    className={
                      footerSet
                        ? "text animate__animated animate__slideInUp"
                        : ""
                    }
                  >
                    Ready to help?
                  </div>
                </div>
              </div>
              <a
                href="https://www.paypal.com/donate/?hosted_button_id=UHGSRNCC822XC"
                target="_blank"
                rel="noreferrer"
                className="content-head"
              >
                <div className="content">
                  <div className="label">
                    <div className="index-rolling-text">Donate</div>
                  </div>
                  <div class="wrapper">
                    <a
                      href="https://www.paypal.com/donate/?hosted_button_id=UHGSRNCC822XC"
                      target="_blank"
                      rel="noreferrer"
                      class="chevron-d"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="55"
                        height="55"
                        viewBox="0 0 55 55"
                      >
                        <path
                          class="circle"
                          d="M27.5 2C41.6 2 53 13.4 53 27.5S41.6 53 27.5 53 2 41.6 2 27.5 13.4 2 27.5 2M27.5 0C12.3 0 0 12.3 0 27.5S12.3 55 27.5 55 55 42.7 55 27.5 42.7 0 27.5 0L27.5 0z"
                        />
                        <polygon
                          class="arrow"
                          points="34.9 31 28.5 35 28.5 17 26.5 17 26.5 35 20.1 31 19 32.7 27.5 38 36 32.7 "
                        />
                        <polygon
                          class="arrow-2"
                          points="34.9 31 28.5 35 28.5 17 26.5 17 26.5 35 20.1 31 19 32.7 27.5 38 36 32.7 "
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </a>
            </div>
            <div className="top">
              <div className="logo-box">
                {/* <div className="logo">Site Under Construction</div> */}
              </div>
              <div className="info-container">
                <div className="list-container">
                  <ul style={{ padding: "0", listStyle: "none" }}>
                    {/* <li className="address">
                        Address Here
                        <br />
                        Here
                        <br />
                        Here
                      </li> */}
                    {/* <li className="phone">+34 93 350 55 08</li> */}
                    <li className="email-container">
                      <a className="email" href="mailto:info@beytna.foundation">
                        info@beytna.foundation
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="list-container-2">
                  <ul style={{ padding: "0", listStyle: "none" }}>
                    {/* <li className="phone">phone number</li> */}
                    <li className="email-container">
                      <a className="email" href="mailto:info@beytna.foundation">
                        info@beytna.foundation
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="list-container-3">
                  <ul style={{ padding: "0", listStyle: "none" }}>
                    <li className="legal">
                      <a className="item" href="/#">
                        Legal disclaimer
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bottom">
              <div className="item">
                ©2022 Beytna Charitable Foundation. All rights reserved.
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
